:root {
  /* General */
  --black: hsl(0deg 0% 0%);
  --black-20: rgb(13 13 13 / 40%);
  --black-90: hsl(0deg 0% 5% / 100%);
  --white: hsl(0deg 0% 100%);
  --inherit: inherit;
  --transparent: transparent;
  --clr-gray-50: hsl(0deg 0% 95%);
  --clr-gray-100: hsl(240deg 9.1% 91.8%);
  --clr-gray-150: hsl(210deg 23% 90%);
  --clr-gray-200: hsl(240deg 7.3% 84%);
  --clr-gray-250: hsl(220deg 3% 83%);
  --clr-gray-300: hsl(240deg 5.6% 73%);
  --clr-gray-350: hsl(207deg 7% 73% / 100%);
  --clr-gray-400: hsl(240deg 5.3% 58%);
  --clr-gray-500: hsl(240deg 3.7% 44%);
  --clr-gray-550: hsl(210deg 3% 36%);
  --clr-gray-600: hsl(240deg 5% 35.5%);
  --clr-gray-700: hsl(204deg 8% 24% / 100%);
  --clr-gray-750: hsl(0deg 0% 5% / 100%);
  --clr-gray-800: hsl(240deg 4.6% 22%);
  --clr-gray-900: hsl(240deg 5.7% 18.2%);
  --clr-gray-950: hsl(240deg 5.1% 15%);
  --clr-teal-50: hsl(195deg 20% 96% / 100%);
  --clr-teal-100: hsl(187.5deg 19% 91.8%);
  --clr-teal-400: hsl(188.8deg 38.2% 34.9%);
  --clr-teal-500: hsl(188.5deg 89.5% 18.6%);
  --clr-teal-600: hsl(189deg 90.1% 15.9%);
  --clr-blue-200: hsl(197.1deg 77.8% 96.5%);
  --clr-blue-500: hsl(205.2deg 84.1% 39.4%);
  --clr-grey-300: hsl(206.7deg 6.6% 73.1%);
  --clr-grey-500: hsl(210deg 2.4% 50.2%);
  --clr-grey-900: hsl(220deg 2.4% 24.9%);
  --clr-gold-100: hsl(36deg 62.5% 96.9%);
  --clr-gold-200: hsl(34.8deg 62.2% 78.2%);
  --clr-gold-500: hsl(34.5deg 62.3% 68.8%);

  /* Red */
  --clr-red-700: #d63333;

  /* Green */
  --clr-green-500: #4aa94a;
  --clr-green-600: #5d7875;
  --clr-green-900: #496a6a;

  /* Primary colors */
  --my-toolbox-color-primary: var(--clr-teal-500);
  --my-toolbox-color-primary-hover: var(--clr-teal-400);

  /* Gold */
  --my-toolbox-color-secondary: var(--clr-gold-500);
  --my-toolbox-color-secondary-hover: var(--clr-gold-200);

  /* Grey */
  --my-toolbox-color-grey-dark: var(--clr-grey-900);
  --my-toolbox-color-grey-light: var(--clr-grey-300);
  --my-toolbox-color-teal-light: var(--clr-teal-100);

  /* Blue */
  --my-toolbox-color-blue-light: var(--clr-blue-200);
  --my-toolbox-color-blue-functional: var(--clr-blue-600);

  /* Background */
  --my-toolbox-background-primary: var(--clr-teal-100);
  --my-toolbox-background-secondary: var(--clr-gold-100);

  /* Font */
  --my-toolbox-font: var(--clr-grey-900);

  /* Pills */
  --my-toolbox-pills: var(--clr-green-900);

  /* Links */
  --my-toolbox-link: var(--clr-blue-500);

  /* Buttons */
  --my-toolbox-button-primary: var(--my-toolbox-color-primary);
  --my-toolbox-button-primary-hover: var(--my-toolbox-color-primary-hover);
  --my-toolbox-button-secondary: var(--my-toolbox-color-secondary);
  --my-toolbox-button-secondary-hover: var(--my-toolbox-color-secondary-hover);
  --my-toolbox-footer: var(--clr-gray-900);
  --my-toolbox-loader-bg: var(--black-20);
  --my-toolbox-border-gray: var(--clr-gray-300);
  --my-toolbox-border-gray-dark: var(--clr-gray-900);
  --my-toolbox-tagline: var(--clr-gray-400);
  --my-toolbox-text-gray: var(--clr-gray-900);
  --my-toolbox-text-gray-dark: var(--clr-gray-900);
  --my-toolbox-background-gray-light: var(--clr-gray-50);
  --my-toolbox-form-error: var(--clr-red-700);
  --my-toolbox-form-success: var(--clr-green-500);
  --my-toolbox-select-primary: var(--clr-teal-500);

  /* Tooltip */
  --my-toolbox-tooltip: var(--clr-teal-500);
  --my-toolbox-tooltip-bg: var(--clr-gray-50);
  --my-toolbox-tooltip-text: var(--clr-teal-500);
  --my-toolbox-tooltip-text-hover: var(--clr-gray-900);
  --my-toolbox-tooltip-text-focus: var(--clr-gray-900);
  --my-toolbox-tooltip-text-active: var(--clr-gray-900);
  --my-toolbox-tooltip-border: var(--clr-gray-900);

  /* Status */
  --my-toolbox-status-active: var(--clr-blue-500);
  --my-toolbox-status-archived: var(--my-toolbox-color-secondary);

  /* Inputs */
  --my-toolbox-input-placeholder: var(--clr-gray-550);
}
