:root {
  --font-family: 'Source Sans Pro', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto,
    'Helvetica Neue', arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  /* Theme specific settings */
  --mt-font-weight-light: 300;
  --mt-font-weight-normal: 400;
  --mt-font-weight-semi: 600;
  --mt-font-weight-bold: 700;

  /* Font sizes */
  --mt-font-size-10: 0.625rem;
  --mt-font-size-12: 0.75rem;
  --mt-font-size-14: 0.875rem;
  --mt-font-size-16: 1rem;
  --mt-font-size-18: 1.125rem;
  --mt-font-size-20: 1.25rem;
  --mt-font-size-22: 1.375rem;
  --mt-font-size-24: 1.5rem;
  --mt-font-size-28: 1.75rem;
  --mt-font-size-32: 2rem;
  --mt-font-size-40: 2.5rem;

  /* Line heights */
  --mt-line-height-24: 24px;
  --mt-line-height-28: 28px;
  --mt-line-height-36: 36px;
  --mt-line-height-48: 48px;
}

* {
  font-family: var(--font-family);
}

h1 {
  font-size: var(--mt-font-size-40);
  font-weight: var(--mt-font-weight-bold);
}

h2 {
  font-size: var(--mt-font-size-40);
  font-weight: var(--mt-font-weight-bold);
}

h3 {
  font-size: var(--mt-font-size-32);
  font-weight: var(--mt-font-weight-bold);
}

h4 {
  font-size: var(--mt-font-size-28);
  font-weight: var(--mt-font-weight-bold);
}

h5 {
  font-size: var(--mt-font-size-24);
  font-weight: var(--mt-font-weight-bold);
}

p {
  font-size: var(--mt-font-size-18);
  line-height: var(--mt-line-height-28);
}

a {
  color: var(--my-toolbox-link);
  text-decoration: none;
  transition: color 0.3s ease-in;

  &:hover {
    color: var(--my-toolbox-link-hover);
    text-decoration: underline;
  }
}
